// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-js": () => import("/opt/build/repo/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("/opt/build/repo/src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-coffee-index-js": () => import("/opt/build/repo/src/pages/coffee/index.js" /* webpackChunkName: "component---src-pages-coffee-index-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interesting-js": () => import("/opt/build/repo/src/pages/interesting.js" /* webpackChunkName: "component---src-pages-interesting-js" */),
  "component---src-pages-now-index-js": () => import("/opt/build/repo/src/pages/now/index.js" /* webpackChunkName: "component---src-pages-now-index-js" */)
}

